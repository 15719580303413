export const LogoLink = ({link, image}) => {



  return (

    <a href={link}>
      <img src={image} alt="LeetCode Logo" className=" w-16" />
    </a>

  )

}

